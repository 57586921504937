<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          rounded
          color="warning"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Confirm Station Pickup
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirm Pickup
        </v-card-title>
        <div class="text-h5 text-center">
          Parcel ID<br />
          <span class="font-weight-thin">
            {{ Package.trackingNumber }}
          </span>
          <br />
          Receiver
          <br />
          <span class="font-weight-thin">
            {{ Package.deliveryDetails.name }}</span
          >
          <br />
          <span class="font-weight-thin">
            {{ Package.deliveryDetails.mobile }}</span
          >
          <br />
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="parcelStationPickup"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["Package"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    parcelStationPickup() {
      let payload = {
        token: this.getToken,
        parcelId: this.Package._id,
      };
      this.$store.dispatch("parcelStationPickup", payload);
      this.dialog = false;
    },
  },
};
</script>