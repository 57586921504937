<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="AllFulfillmentCenter"
      sort-by="calories"
      class="elevation-1"
      show-expand
      item-key="_id"
      :single-expand="true"
      hide-default-footer
      @page-count="pageCount = $event"
      :loading="TableLoading"
      loading-text="Loading... Please wait"
      :items-per-page="20"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogHandOver" max-width="550px">
            <v-card class="pa-7">
              <div class="text-h5" v-if="rider.personalInfo">
                Pickup By <br />
                <span class="font-weight-thin">
                  {{ rider.personalInfo.firstName }}
                  {{ rider.personalInfo.lastName }}
                </span>
                <br />
                Deliver To Station <br />
                <span class="font-weight-thin"> {{ item.locationName }}</span>
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th class="text-left">Rider</th>
                      <th class="text-left">shippingFee</th>
                      <th class="text-left">Pay-out</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ rider.personalInfo.firstName }}
                        {{ rider.personalInfo.lastName }}
                      </td>
                      <td>
                        {{ DisplayCurrency(Package.shippingFee) }}
                      </td>
                      <td v-if="Package.reAssignShippingFee">
                        {{ DisplayCurrency(sharingCal) }}
                      </td>
                      <td v-else>
                        {{ DisplayCurrency(sharingCal) }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-text-field
                  class="mt-5"
                  label="Sharing ratio %"
                  filled
                  dense
                  v-model="riderPayoutPercenter"
                ></v-text-field>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="dialogHandOver = false"
                  >Cancel</v-btn
                >
                <v-btn color="pink" @click="PickupAndHandOver()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="handOver(item)" color="primary" dark>
          <v-icon> mdi-gavel </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import currencyFormatter from "currency-formatter";

export default {
  props: ["rider", "Package", "closeAllModal", "center"],

  data: () => ({
    dialog: false,
    item: {},
    dialogHandOver: false,
    headers: [
      {
        text: "Station",
        align: "start",
        sortable: false,
        value: "locationName",
      },
      { text: "Hand Over", value: "actions", sortable: false },
    ],
    desserts: [],
    riderPayoutPercenter: 50,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    AllFulfillmentCenter() {
      let FulfillmentCenter = this.$store.getters.AllFulfillmentCenter;
      return FulfillmentCenter;
    },
    sharingCal() {
      if (this.riderPayoutPercenter < 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "value can't be less than 0 and the value can't be greater than 100"
        );
        return;
      }
      if (this.Package.reAssignShippingFee) {
        return (
          (this.riderPayoutPercenter / 100) * this.Package.reAssignShippingFee
        );
      } else {
        return (this.riderPayoutPercenter / 100) * this.Package.shippingFee;
      }
    },
  },

  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    handOver(payload) {
      this.item = payload;
      this.dialogHandOver = true;
    },

    PickupAndHandOver() {
      let payload = {
        token: this.getToken,
        riderId: this.rider._id,
        parcelId: this.Package._id,
        action: "Pickup And HandOver",
        stationDeliveryId: this.item._id,
        riderPayoutPercenter: this.riderPayoutPercenter,
      };
      this.$store.dispatch("assignPackageToRider", payload);
      this.closeAllModal();
      this.dialog = false;
      this.dialogHandOver = false;
    },
  },
  created() {
    let payload = {
      token: this.getToken,
      page: 1,
    };
    this.$store.dispatch("getAllFulfillmentCenter", payload);
  },
};
</script>
