<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded color="pink" dark v-bind="attrs" v-on="on">
          Assign To Rider
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat dark color="pink">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="pa-2">
          <v-card-title class="text-h5">
            PickUp Address: <v-spacer /> {{ Package.pickUpLocation.City }},
            {{ Package.pickUpLocation.Street }}
          </v-card-title>
          <v-divider />
          <v-card-title class="text-h5">
            Delivery Address: <v-spacer /> {{ Package.deliveryDetails.City }},
            {{ Package.deliveryDetails.Street }}
          </v-card-title>
          <v-divider />
          <v-card-title class="text-h5">
            Delivery Type: <v-spacer /> {{ Package.deliveryType }},
          </v-card-title>
          <v-divider />
          <v-data-table
            :items="nearByRider"
            :headers="dessertHeaders"
            item-key="_id"
            show-expand
            :single-expand="true"
            hide-default-footer
            @page-count="pageCount = $event"
            class="elevation-1"
            :loading="TableLoading"
            loading-text="Loading... Please wait"
            :items-per-page="20"
          >
            <template v-slot:item.dis="{ item }">
              <span>{{ parseFloat(item.dis / 1000).toFixed(2) }} Km </span>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div
                  class="text-center display-1 ma-3"
                  v-if="item.UnDeliverParcel.length > 0"
                >
                  Rider Current Packages
                </div>
                <v-simple-table dark v-if="item.UnDeliverParcel.length > 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">NO</th>
                        <th class="text-left">PickUp Address</th>
                        <th class="text-left">Delivery Addresses</th>
                        <th class="text-left">Distance Km</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in item.UnDeliverParcel"
                        :key="item._id"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{ item.pickUpLocation.City }},
                          {{ item.pickUpLocation.Street }}
                        </td>
                        <td>
                          <div>
                            {{ item.deliveryDetails.City }},
                            {{ item.deliveryDetails.Street }}
                          </div>
                        </td>
                        <td>
                          <div>
                            {{ item.distance }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="text-center ma-5">
                  <v-btn
                    color="pink"
                    @click="
                      dialog1 = true;
                      rider = item;
                    "
                    >Assign</v-btn
                  >
                  <v-dialog fullscreen v-model="dialog1">
                    <v-toolbar flat dark color="info">
                      <v-btn icon dark @click="dialog1 = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="dialog1 = false">
                          close
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card>
                      <v-card-title class="text-h5 font-weight-thin">
                        PickUp Address: <v-spacer />
                        {{ Package.pickUpLocation.Street }}
                      </v-card-title>
                      <v-divider />
                      <v-card-title class="text-h5 font-weight-thin">
                        Delivery Address: <v-spacer />
                        {{ Package.deliveryDetails.Street }}
                      </v-card-title>
                      <v-divider />
                      <v-card-title class="text-h5 font-weight-thin">
                        Assign Package To: <v-spacer />
                        {{ item.personalInfo.firstName }}
                        {{ item.personalInfo.lastName }}
                      </v-card-title>
                      <v-divider />
                      <v-card-actions class="justify-center">
                        <v-btn
                          dark
                          rounded
                          color="info"
                          @click="
                            handOverToStation = true;
                            handOver = false;
                          "
                        >
                          PickUp And Deliver To Station
                        </v-btn>
                        <v-btn
                          dark
                          rounded
                          color="warning"
                          @click="
                            handOver = !handOver;
                            handOverToStation = false;
                          "
                        >
                          PickUp And Handover
                        </v-btn>
                        <v-btn
                          rounded
                          dark
                          color="pink"
                          @click="
                            PickUpAndDeliver(
                              item._id,
                              Package._id,
                              'Pickup and Deliver'
                            )
                          "
                        >
                          PickUp And Deliver
                        </v-btn>
                      </v-card-actions>
                      <div>
                        <v-card-title
                          v-if="handOver"
                          class="text-h5 font-weight-bold"
                        >
                          <v-spacer></v-spacer>
                          HandOver To Rider
                          <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-title
                          v-if="handOverToStation"
                          class="text-h5 font-weight-bold"
                        >
                          <v-spacer></v-spacer>
                          Delivery To Station
                          <v-spacer></v-spacer>
                        </v-card-title>

                        <PickupAndHandOver
                          v-if="handOver"
                          :Package="Package"
                          :Riders="nearByRider"
                          :rider="item"
                          :closeAllModal="closeAllModal"
                        />
                        <HandOverToStation
                          v-if="handOverToStation"
                          :Package="Package"
                          :rider="rider"
                          :closeAllModal="closeAllModal"
                        />
                      </div>
                    </v-card>
                  </v-dialog>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PickupAndHandOver from "./PickupAndHandOver";
import HandOverToStation from "./HandOverToStation";

export default {
  components: { PickupAndHandOver, HandOverToStation },
  props: ["Package"],
  data() {
    return {
      dialog: false,
      handOver: false,
      handOverToStation: false,
      dialog1: false,
      expanded: [],
      rider: {},
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Rider Name",
          align: "start",
          sortable: false,
          value: "personalInfo.firstName",
        },
        {
          text: "Vehicle Type",
          align: "start",
          sortable: false,
          value: "vehicleType",
        },
        { text: "Current Location", value: "currentLocation.Street" },
        {
          text: "Current Package No.",
          align: "center",
          value: "UnDeliverParcel.length",
        },
        { text: "Distance Km", align: "center", value: "dis" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    totalPackage(payload) {
      return payload.UnDeliverParcel.length;
    },
    PickUpAndDeliver(riderId, packageId, action) {
      let payload = {
        token: this.getToken,
        riderId: riderId,
        parcelId: packageId,
        action: action,
      };
      this.$store.dispatch("assignPackageToRider", payload);
      this.dialog = false;
      this.handOver = false;
      this.dialog1 = false;
    },

    closeAllModal() {
      this.dialog = false;
      this.handOver = false;
      this.dialog1 = false;
    },
  },
  computed: {
    nearByRider() {
      return this.$store.getters.nearByRider;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          latitude: this.Package.pickUpLocation.latitude,
          longitude: this.Package.pickUpLocation.longitude,
          token: this.getToken,
          vehicleType: this.Package.deliveryType,
        };
        this.$store.dispatch("GetNearByRiderToParcelPickUp", payload);
      }
    },
  },
};
</script>