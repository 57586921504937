<template>
  <div class="text-end">
    <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on"
          >More Action
          <v-icon right dark> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>
      <v-sheet class="text-center" height="200px">
        <v-btn class="mt-6" text color="error" @click="sheet = !sheet">
          close
        </v-btn>
        <div class="text-center my-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="ma-3">
              <AssignToRIder v-if="!item.riderDetails" :Package="item" />
              <ReAssignParcelToDifferentRider v-else :parcel="item" />
            </div>
            <div class="ma-3">
              <ConfirmStationPickup :Package="item" />
            </div>
            <div>
              <ParcelArriverNotification :Package="item" />
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import ParcelArriverNotification from "./ParcelArriverNotification.vue";
import AssignToRIder from "./AssignToRIder.vue";
import ConfirmStationPickup from "./ConfirmStationPickup.vue";
import ReAssignParcelToDifferentRider from "./ReAssignParcelToDifferentRider.vue";
export default {
  props: ["item"],
  components: {
    ParcelArriverNotification,
    AssignToRIder,
    ConfirmStationPickup,
    ReAssignParcelToDifferentRider,
  },
  data: () => ({
    sheet: false,
  }),
};
</script>