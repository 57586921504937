<template>
  <div>
    <v-container>
      <div flat>
        <v-tabs v-model="tab" background-color="info" dark>
          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.content">
            <div flat>
              <v-data-table
                :headers="dessertHeaders"
                :items="AwaitingPackage"
                show-expand
                item-key="_id"
                :single-expand="true"
                hide-default-footer
                @page-count="pageCount = $event"
                class="elevation-1"
                :loading="TableLoading"
                loading-text="Loading... Please wait"
                :items-per-page="20"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ DisTime(item.createdAt) }}</span>
                </template>
                <template v-slot:item.receivedBy="{ item }">
                  <span v-if="item.receivedBy"
                    >{{ item.receivedBy.personalInfo.firstName }}
                    {{ item.receivedBy.personalInfo.lastName }}</span
                  >
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div v-if="status == 'incomingParcel'" class="text-end">
                      <v-btn
                        dark
                        color="pink"
                        class="mt-2"
                        @click="
                          confirmParcel = true;
                          parcel = item;
                        "
                      >
                        Parcel Received
                      </v-btn>
                    </div>
                    <div
                      v-if="status == 'receivedParcel'"
                      class="text-end ma-2"
                    >
                      <MoreAction :item="item" />
                    </div>
                    <v-card-actions v-if="item.comment.length > 0">
                      <v-spacer></v-spacer>
                      <div class="text-center display-1 ma-3">
                        Ticket Issues
                      </div>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-simple-table dark v-if="item.comment.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Message</th>
                            <th class="text-left">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(ite, index) in item.comment" :key="index">
                            <td>
                              {{ ite.fullName }}
                            </td>
                            <td>{{ ite.comment }}</td>
                            <td>{{ DisplayTimeFormat(ite.time) }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <div class="text-center display-1 ma-3">
                        Sender Details
                      </div>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Sender Name</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">PickUp Address</th>
                          </tr>
                        </thead>
                        <tbody v-if="item.senderDetails">
                          <tr>
                            <td>
                              {{ item.senderDetails.firstName }}
                              {{ item.senderDetails.lastName }}
                            </td>
                            <td>{{ item.senderDetails.phoneNumber }}</td>
                            <td>{{ item.pickUpLocation.Street }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td>
                              {{ item.offlineSenderDetails.firstName }}
                              {{ item.offlineSenderDetails.lastName }}
                            </td>
                            <td>{{ item.offlineSenderDetails.phoneNumber }}</td>
                            <td>{{ item.pickUpLocation.Street }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div class="text-center display-1 ma-3">
                      Delivery Details
                    </div>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Contact Person</th>
                            <th class="text-left">Delivery Address</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">Distance Km</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ item.deliveryDetails.name }}</td>
                            <td>{{ item.deliveryDetails.Street }}</td>
                            <td>{{ item.deliveryDetails.mobile }}</td>
                            <td>{{ item.deliveryDetails.distance }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <div
                      v-if="item.riderDetails"
                      class="text-center display-1 ma-3"
                    >
                      Rider Details
                    </div>
                    <v-simple-table dark>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Staff Name</th>
                            <th class="text-left">Phone Number</th>
                            <th class="text-left">Assign Time</th>
                            <th class="text-left">Current Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{ item.riderDetails.personalInfo.firstName }}
                              {{ item.riderDetails.personalInfo.lastName }}
                            </td>
                            <td>
                              {{ item.riderDetails.personalInfo.phoneNumber }}
                            </td>
                            <td>{{ DisplayTimeFormat(item.assignTime) }}</td>
                            <td v-if="item.riderDetails.currentLocation">
                              {{ item.riderDetails.currentLocation.Street }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    {{ item.null }}
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-dialog v-model="confirmParcel" max-width="550px">
        <v-card class="pa-7">
          <div class="text-h5 text-center">
            Received Confirmation of Parcel <br />
            <span class="font-weight-thin">
              {{ parcel.trackingNumber }}
            </span>
            <br />
            Received By <br />
            <span class="font-weight-thin">
              {{ getUser.personalInfo.firstName }}
              {{ getUser.personalInfo.lastName }}
            </span>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="confirmParcel = false"
              >Cancel</v-btn
            >
            <v-btn color="pink" @click="receivedParcel()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="AwaitingPackageTotalItems"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import MoreAction from "../components/MoreAction.vue";

export default {
  components: {
    MoreAction: MoreAction,
  },
  data() {
    return {
      confirmParcel: false,
      expanded: [],
      singleExpand: false,
      tab: null,
      parcel: "",
      items: [
        { tab: "Received Parcel", content: "ReceivedParcel" },
        { tab: "Incoming Parcel", content: "IncomingParcel" },
      ],
      dessertHeaders: [
        {
          text: "Tracking No",
          align: "start",
          value: "trackingNumber",
        },
        { text: "Status", value: "Status" },
        { text: "Delivery Address", value: "deliveryDetails.Street" },
        { text: "Received By", align: "start", value: "receivedBy" },
        { text: "Request Date", value: "createdAt" },
      ],
      status: "receivedParcel",
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    AwaitingPackageTotalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    AwaitingPackage() {
      return this.$store.getters.AllParcels;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    receivedParcel() {
      let payload = {
        token: this.getToken,
        parcelId: this.parcel._id,
      };
      this.$store.dispatch("stationReceivedParcel", payload);
      this.confirmParcel = false;
    },
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    GetPackages() {
      let payload = {
        page: this.page,
        token: this.getToken,
        status: this.status,
      };
      this.$store.dispatch("getStationParcel", payload);
    },
  },
  created() {
    this.GetPackages();
  },
  watch: {
    tab: function (val) {
      if (val == 0) {
        this.status = "receivedParcel";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "receivedParcel",
        };
        this.$store.dispatch("getStationParcel", payload);
      }
      if (val == 1) {
        this.status = "incomingParcel";
        this.Currpage = 1;
        let payload = {
          page: 1,
          token: this.getToken,
          status: "incomingParcel",
        };
        this.$store.dispatch("getStationParcel", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          status: this.status,
        };
        this.$store.dispatch("getStationParcel", payload);
        return;
      }
    },
  },
};
</script>