<template>
  <v-data-table
    :headers="headers"
    :items="Riders"
    sort-by="calories"
    class="elevation-1"
    show-expand
    item-key="_id"
    :single-expand="true"
    hide-default-footer
    @page-count="pageCount = $event"
    :loading="TableLoading"
    loading-text="Loading... Please wait"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialogHandOver" max-width="550px">
          <v-card class="pa-7">
            <div class="text-h5" v-if="rider.personalInfo">
              Handover To <br />
              {{ rider.personalInfo.firstName }}
              {{ rider.personalInfo.lastName }}
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left">Rider</th>
                    <th class="text-left">shippingFee</th>
                    <th class="text-left">Pay-out</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{ rider.personalInfo.firstName }}
                      {{ rider.personalInfo.lastName }}
                    </td>
                    <td>
                      {{ DisplayCurrency(Package.shippingFee) }}
                    </td>
                    <td v-if="Package.reAssignShippingFee">
                      {{ DisplayCurrency(sharingCal) }}
                    </td>
                    <td v-else>
                      {{ DisplayCurrency(sharingCal) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ Rider.personalInfo.firstName }}
                      {{ Rider.personalInfo.lastName }}
                    </td>
                    <td>
                      {{ DisplayCurrency(Package.shippingFee) }}
                    </td>
                    <td v-if="Package.reAssignShippingFee">
                      {{
                        DisplayCurrency(
                          Package.reAssignShippingFee - sharingCal
                        )
                      }}
                    </td>
                    <td v-else>
                      {{ DisplayCurrency(Package.shippingFee - sharingCal) }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-text-field
                class="mt-5"
                label="Sharing ratio %"
                filled
                dense
                v-model="riderPayoutPercenter"
              ></v-text-field>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="dialogHandOver = false"
                >Cancel</v-btn
              >
              <v-btn color="pink" @click="PickupAndHandOver()">Proceed</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn @click="handOver(item)" color="primary" dark>
        <v-icon> mdi-gavel </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import currencyFormatter from "currency-formatter";

export default {
  props: ["Riders", "Rider", "Package", "closeAllModal"],

  data: () => ({
    dialog: false,
    rider: {},
    dialogHandOver: false,
    headers: [
      {
        text: "Rider Name",
        align: "start",
        sortable: false,
        value: "personalInfo.firstName",
      },
      {
        text: "Current Location",
        value: "currentLocation.Street",
      },
      {
        text: "Current Package No.",
        align: "center",
        value: "UnDeliverParcel.length",
      },
      { text: "Hand Over", value: "actions", sortable: false },
    ],
    desserts: [],
    riderPayoutPercenter: 50,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    sharingCal() {
      if (this.riderPayoutPercenter < 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "value can't be less than 0 and the value can't be greater than 100"
        );
        return;
      }
      if (this.Package.reAssignShippingFee) {
        return (
          (this.riderPayoutPercenter / 100) * this.Package.reAssignShippingFee
        );
      } else {
        return (this.riderPayoutPercenter / 100) * this.Package.shippingFee;
      }
    },
  },

  methods: {
    handOver(item) {
      this.rider = item;
      this.dialogHandOver = true;
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },

    PickupAndHandOver() {
      let payload = {
        token: this.getToken,
        riderId: this.Rider._id,
        handOverTo: this.rider._id,
        parcelId: this.Package._id,
        action: "Pickup And HandOver",
        riderPayoutPercenter: this.riderPayoutPercenter,
      };
      this.$store.dispatch("assignPackageToRider", payload);
      this.closeAllModal();
      this.dialog = false;
      this.dialogHandOver = false;
    },
  },
};
</script>
