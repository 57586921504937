var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","color":"pink","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Assign To Rider ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"pink"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" close ")])],1)],1),_c('v-container',{staticClass:"pa-2"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" PickUp Address: "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.Package.pickUpLocation.City)+", "+_vm._s(_vm.Package.pickUpLocation.Street)+" ")],1),_c('v-divider'),_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delivery Address: "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.Package.deliveryDetails.City)+", "+_vm._s(_vm.Package.deliveryDetails.Street)+" ")],1),_c('v-divider'),_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delivery Type: "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.Package.deliveryType)+", ")],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.nearByRider,"headers":_vm.dessertHeaders,"item-key":"_id","show-expand":"","single-expand":true,"hide-default-footer":"","loading":_vm.TableLoading,"loading-text":"Loading... Please wait","items-per-page":20},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.dis",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseFloat(item.dis / 1000).toFixed(2))+" Km ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.UnDeliverParcel.length > 0)?_c('div',{staticClass:"text-center display-1 ma-3"},[_vm._v(" Rider Current Packages ")]):_vm._e(),(item.UnDeliverParcel.length > 0)?_c('v-simple-table',{attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("NO")]),_c('th',{staticClass:"text-left"},[_vm._v("PickUp Address")]),_c('th',{staticClass:"text-left"},[_vm._v("Delivery Addresses")]),_c('th',{staticClass:"text-left"},[_vm._v("Distance Km")])])]),_c('tbody',_vm._l((item.UnDeliverParcel),function(item,index){return _c('tr',{key:item._id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(item.pickUpLocation.City)+", "+_vm._s(item.pickUpLocation.Street)+" ")]),_c('td',[_c('div',[_vm._v(" "+_vm._s(item.deliveryDetails.City)+", "+_vm._s(item.deliveryDetails.Street)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(item.distance)+" ")])])])}),0)]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"text-center ma-5"},[_c('v-btn',{attrs:{"color":"pink"},on:{"click":function($event){_vm.dialog1 = true;
                    _vm.rider = item;}}},[_vm._v("Assign")]),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"info"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog1 = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog1 = false}}},[_vm._v(" close ")])],1)],1),_c('v-card',[_c('v-card-title',{staticClass:"text-h5 font-weight-thin"},[_vm._v(" PickUp Address: "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.Package.pickUpLocation.Street)+" ")],1),_c('v-divider'),_c('v-card-title',{staticClass:"text-h5 font-weight-thin"},[_vm._v(" Delivery Address: "),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.Package.deliveryDetails.Street)+" ")],1),_c('v-divider'),_c('v-card-title',{staticClass:"text-h5 font-weight-thin"},[_vm._v(" Assign Package To: "),_c('v-spacer'),_vm._v(" "+_vm._s(item.personalInfo.firstName)+" "+_vm._s(item.personalInfo.lastName)+" ")],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"dark":"","rounded":"","color":"info"},on:{"click":function($event){_vm.handOverToStation = true;
                          _vm.handOver = false;}}},[_vm._v(" PickUp And Deliver To Station ")]),_c('v-btn',{attrs:{"dark":"","rounded":"","color":"warning"},on:{"click":function($event){_vm.handOver = !_vm.handOver;
                          _vm.handOverToStation = false;}}},[_vm._v(" PickUp And Handover ")]),_c('v-btn',{attrs:{"rounded":"","dark":"","color":"pink"},on:{"click":function($event){return _vm.PickUpAndDeliver(
                            item._id,
                            _vm.Package._id,
                            'Pickup and Deliver'
                          )}}},[_vm._v(" PickUp And Deliver ")])],1),_c('div',[(_vm.handOver)?_c('v-card-title',{staticClass:"text-h5 font-weight-bold"},[_c('v-spacer'),_vm._v(" HandOver To Rider "),_c('v-spacer')],1):_vm._e(),(_vm.handOverToStation)?_c('v-card-title',{staticClass:"text-h5 font-weight-bold"},[_c('v-spacer'),_vm._v(" Delivery To Station "),_c('v-spacer')],1):_vm._e(),(_vm.handOver)?_c('PickupAndHandOver',{attrs:{"Package":_vm.Package,"Riders":_vm.nearByRider,"rider":item,"closeAllModal":_vm.closeAllModal}}):_vm._e(),(_vm.handOverToStation)?_c('HandOverToStation',{attrs:{"Package":_vm.Package,"rider":_vm.rider,"closeAllModal":_vm.closeAllModal}}):_vm._e()],1)],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }