<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="790">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" dark v-bind="attrs" v-on="on">
          ReAssign Parcel
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Re-Assign Parcel :
          <v-spacer /> {{ parcel.trackingNumber }} || DeliveryType :
          {{ parcel.deliveryType }}
        </v-card-title>
        <v-card-title class="text-h5">
          Current Rider :<v-spacer />
          {{ parcel.riderDetails.personalInfo.firstName }}
          {{ parcel.riderDetails.personalInfo.lastName }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Phone</th>
                  <th class="text-left">Vehicle Type</th>
                  <th class="text-left">Current Location</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getRiders" :key="item._id">
                  <td>
                    {{ item.personalInfo.firstName }}
                    {{ item.personalInfo.lastName }}
                  </td>
                  <td>{{ item.personalInfo.phoneNumber }}</td>
                  <td>{{ item.vehicleType }}</td>
                  <td v-if="item.currentLocation">
                    {{ item.currentLocation.Street }}
                    {{ item.currentLocation.City }}
                  </td>
                  <td>
                    <v-btn small color="pink" @click="ReAssign(item)"
                      >Re-Assign</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="totalItems"></v-pagination>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
        <v-dialog v-model="dialog1" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Shipping Fee : <v-spacer />
              {{
                DisplayCurrency(
                  parcel.reAssignShippingFee || parcel.shippingFee
                )
              }}
            </v-card-title>

            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Rider Name</th>
                      <th class="text-left">Pay-out</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b> Current Rider </b>
                        {{ parcel.riderDetails.personalInfo.firstName }}
                        {{ parcel.riderDetails.personalInfo.lastName }}
                      </td>
                      <td v-if="parcel.reAssignShippingFee">
                        {{
                          DisplayCurrency(
                            parcel.reAssignShippingFee - sharingCal
                          )
                        }}
                      </td>
                      <td v-else>
                        {{ DisplayCurrency(parcel.shippingFee - sharingCal) }}
                      </td>
                    </tr>
                    <tr v-if="selectedRider">
                      <td>
                        <b> Re-Assign Rider </b>
                        {{ selectedRider.personalInfo.firstName }}
                        {{ selectedRider.personalInfo.lastName }}
                      </td>
                      <td>{{ DisplayCurrency(sharingCal) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-text-field
                class="mt-5"
                label="Sharing ratio %"
                filled
                dense
                v-model="reAssignSharingPercentage"
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="pink" @click="ReAssignParcel()"> Proceed </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import currencyFormatter from "currency-formatter";

export default {
  props: ["parcel", "status"],
  data() {
    return {
      dialog: false,
      dialog1: false,
      page: 1,
      Currpage: 1,
      selectedRider: {},
      reAssignSharingPercentage: 50,
      newShippingFees: "",
    };
  },
  computed: {
    getRiders() {
      return this.$store.getters.getRiders;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    sharingCal() {
      if (this.selectedRider > 100 || this.reAssignSharingPercentage < 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "value can't be less than 0 and the value can't be greater than 100"
        );
        return;
      }
      if (this.parcel.reAssignShippingFee) {
        return (
          (this.reAssignSharingPercentage / 100) *
          this.parcel.reAssignShippingFee
        );
      } else {
        return (this.reAssignSharingPercentage / 100) * this.parcel.shippingFee;
      }
    },
  },
  methods: {
    ReAssignParcel() {
      if (
        this.reAssignSharingPercentage > 100 ||
        this.reAssignSharingPercentage < 0
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Error");
        return;
      }
      if (this.selectedRider._id == "" || this.parcel._id == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Error");
        return;
      }
      let payload = {
        token: this.getToken,
        status: this.status,
        parcelId: this.parcel._id,
        riderId: this.selectedRider._id,
        reAssignSharingPercentage: this.reAssignSharingPercentage,
      };
      this.$store.dispatch("ReAssignParcel", payload);
      this.dialog = false;
      this.dialog1 = false;
    },
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "NGN",
      });
    },
    ReAssign(payload) {
      this.selectedRider = payload;
      this.dialog1 = true;
    },
    getAllRider() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAllRider", payload);
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllRider", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllRider", payload);
        return;
      }
    },
  },
};
</script>