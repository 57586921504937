<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          v-if="Package.arrivalNotification"
          color="green"
          :content="Package.arrivalNotification"
        >
          <v-btn dark rounded color="green" v-bind="attrs" v-on="on">
            Arriver Notification
          </v-btn>
        </v-badge>
        <v-btn v-else dark rounded color="green" v-bind="attrs" v-on="on">
          Arriver Notification
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Parcel Arriver Notification
        </v-card-title>
        <div class="text-h5 text-center">
          Parcel ID<br />
          <span class="font-weight-thin">
            {{ Package.trackingNumber }}
          </span>
          <br />
          Receiver
          <br />
          <span class="font-weight-thin">
            {{ Package.deliveryDetails.name }}</span
          >
          <br />
          <span class="font-weight-thin">
            {{ Package.deliveryDetails.mobile }}</span
          >
          <br />
        </div>
        <v-textarea
          class="ma-3"
          filled
          shaped
          auto-grow
          label="Message"
          rows="4"
          v-model="message"
          row-height="20"
        ></v-textarea>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="SendArriverNotification"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["Package"],
  data() {
    return {
      dialog: false,
      message: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    SendArriverNotification() {
      if (this.message == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        token: this.getToken,
        message: this.message,
        phoneNumber: this.Package.deliveryDetails.mobile,
        parcelId: this.Package._id,
      };
      this.$store.dispatch("SendSingleSMS", payload);
      this.message = "";
      this.dialog = false;
    },
  },
};
</script>